






















































































































































































































































































































































































































































































body {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  color: #262626;
  background-color: var(--pale-grey-two);
}

.sidebar-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
  z-index: 900;
}

pre {
  font-family: Source Sans Pro, sans-serif;
  color: #000;
  background: #fff;
  border-radius: 2px;
  font-size: 13px;
  padding: 5px;
  overflow: auto;
}

.p-fluid .form-button-group .p-button {
  width: auto;
}

.v-step.v-tour-step {
  width: min-content;
  min-width: 23rem !important;
  max-width: 30rem !important;
  background-color: #4962f3 !important;
  box-shadow: 0 3px 10px 0 rgba(99, 15, 245, 0.15);
  padding-left: 1.9rem !important;
  padding-right: 1.9rem !important;
  border-radius: 16px !important;
  font-family: Raleway, "Source Sans Pro", sans-serif !important;

  &::before {
    position: fixed;
    inset: 0 0;
    width: 100vw;
    height: 100vh;
    opacity: 0.5;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background-color: var(--brownish-grey);
  }

  .v-step-header,
  .v-step__arrow::before {
    background-color: #4962f3;
  }

  .v-step-header {
    min-width: max-content;

    .v-step-number {
      width: 1.75rem;
      height: 1.75rem;
      border-radius: 100%;
      background-color: #fff;
      color: #4962f3;
      font-size: 1.125rem;
      font-weight: bold;
      user-select: none;
    }

    .v-step-title {
      font-size: 1rem;
      font-weight: bold;
    }
  }

  .v-step-button {
    color: #fff;
    cursor: pointer;

    &:hover {
      color: #f0faeb;
    }
  }

  .v-skip-tour {
    text-decoration: underline;
    cursor: pointer;
    color: #fff;
    font-weight: normal;
    font-size: 1rem;
  }

  .v-step__content {
    padding-top: 1rem;
    font-size: 1rem;
    line-height: 1.56;
    letter-spacing: 0.32px;
    text-align: left;
    color: #fff;
  }

  .v-step-actions {
    width: 100%;
  }
}

.v-tour__target--highlighted {
  box-shadow: 0 3px 20px 0 rgba(255, 255, 255, 0.91) !important;
  z-index: 101 !important;
}

.v-tour-background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  bottom: 0;
  right: 0;
  -webkit-backdrop-filter: blur(0.5px);
  backdrop-filter: blur(0.5px);
  background-color: rgba(112, 112, 112, 0.5);
  z-index: 100;
}

.onboard-welcome.p-dialog {
  border-radius: 10px;

  .p-dialog-header {
    padding: 0;
  }

  .p-dialog-content {
    box-shadow: 0 3px 10px 0 rgba(99, 15, 245, 0.15);
    border-radius: 10px;
    background-color: #303133;
    padding: 3.375rem 8.25rem;
    text-align: center;
    color: #fff;

    p {
      max-width: 40rem;
    }
  }

  .onboard-welcome-button {
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 39rem;
    box-shadow: 0 3px 15px 0 #630ff5;
    border-radius: 2rem;
    background-image: linear-gradient(to bottom, #4962f3, #7954f7);
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.29;
    text-align: center;
    color: #fff;
    cursor: pointer;
    margin-top: 0.5rem;

    &:hover {
      box-shadow: 0 3px 15px 0 #4353ed;
      background-image: linear-gradient(to bottom, #4962f2, #7954f8);
    }
  }
}
